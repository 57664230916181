html,
body {
    height: 100%;
}

body {
    min-width: 300px;
    margin: 0;
    font-family: 'Nunito', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
}

.sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    font-family: 'Amatic SC';
    font-weight: 700;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 16px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.checkbox-control {
    font-weight: bold;
    font-size: 14px;
    line-height: 1.1;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
}

.checkbox-control+.checkbox-control {
    margin-top: 1em;
}

.checkbox-control--disabled {
    color: #959495;
    cursor: not-allowed;
}

input {
    font-family: 'Nunito';
}

button {
    font-family: 'Nunito';
}

input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: #fff;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid #fff;
    border-radius: 0.15em;
    display: grid;
    place-content: center;
}

input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #6B9362;
    background-color: transparent;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

input[type="checkbox"]:disabled {
    --form-control-color: #959495;
    color: #959495;
    cursor: not-allowed;
}

.page-not-found {
    margin: 50px;
    text-align: center;
    flex: 1 1;
}

.page-not-found h1 {
    margin: 0 0 50px 0;
    font-size: 40px;
}

.page-not-found h2 {
    font-size: 30px;
}

#root {
    height: 100%;
}

.app {
    height: 100%;
}

.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

header {
    padding: 5px 0;
}

header .svg {
    display: inline-block;
    width: 50px;
}

header .logo-tagline {
    overflow: hidden;
    float: left;
    width: 240px;
}

header .logo {
    float: left;
    margin: 0 20px;
    text-align: center;
}

header .logo img {
    float: left;
    width: 40px;
    height: 40px;
    margin-top: 10px;
}

header a {
    display: block;
    overflow: hidden;
}

header h1 {
    display: block;
    float: left;
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    margin: 0 0 0 10px;
    position: relative;
}

header a h1 {
    text-decoration: underline;
}

header a:hover h1,
header a:focus h1 {
    color: yellow;
    text-decoration: underline;
}

header .tagline {
    float: left;
    font-family: 'Amatic SC';
    font-size: 20px;
    padding: 0 0 0 20px;
}

header .controls {
    float: right;
    padding: 25px 5px 0 0;
}

@media (min-width: 640px) {
    header .logo-tagline {
        width: auto;
    }

    header .logo-tagline .tagline {
        padding: 30px 0 0 0;
    }

    header .controls {
        padding-top: 10px;
    }
}

footer {
    font-size: 14px;
    text-align: center;
    padding: 10px 0;
    background-color: #1D1E25;
    color: #fff;
}

footer ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

footer ul li {
    padding: 0 20px;
}

footer ul li.copyright {
    font-family: 'Amatic SC';
    font-weight: 700;
    font-size: 20px;
    line-height: 18px;
}

footer ul li.copyright span {
    font-size: 15px;
}

footer a {
    text-decoration: underline;
}

.main {
    flex: 2;
    display: flex;
    flex-direction: column;
}

.main .filter-block {
    font-size: 12px;
    overflow-y: auto;
    padding: 10px 20px;
    height: 100%;
}

.main .filter-block .filter {
    margin-bottom: 20px;
}

.main .filter-block h2 {
    font-size: 30px;
    text-align: center;
    margin-bottom: 10px;
}

.main .filter-block label {
    cursor: pointer;
}

.main .filter-block .no-results {
    font-family: 'Amatic SC';
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
}

.main .map {
    flex: 2;
    width: 100%;
}

.main .map .title {
    font-weight: 700;
    font-family: 'Amatic SC';
    font-size: 22px;
}

.main .map .type {
    font-style: italic;
}

.main .map .date {
    font-style: italic;
}

.main .map .summary {
    margin-top: 10px;
}

.main .map .more {
    margin-top: 10px;
}

.main .map .more a {
    font-weight: bold;
}

.filter-block-wrapper {
    position: relative;
}

.filter-block-wrapper .toggle {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 2000;
    display: inline-block;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.filter-block-wrapper .toggle button {
    margin: 2px 0 0 0;
}

@media (min-width: 640px) {
    .main {
        overflow-y: auto;
        display: flex;
        flex-direction: row;
    }

    .main .map {
        height: auto;
        flex: 1 1;
    }

    .main .filter-block {
        height: auto;
        width: 160px;
    }

    .main .filter-block .filters {
        display: block;
    }

    .main .filters-collapsible .my-collapsible__content {
        display: block !important;
    }

    .filter-block-wrapper .toggle {
        display: none;
    }
}

#map {
    height: 100%;
}

#map .leaflet-container {
    height: 100%;
}

#map .leaflet-marker-icon {
    background-color: transparent;
    border: 0;
}

#map .leaflet-marker-icon .multi-icon {
    background-color: transparent;
    border: 0;
    width: 32px !important;
    height: 32px !important;
}

#map .leaflet-marker-icon .normal-icon {
    background-color: transparent;
    border: 0;
    width: 20px !important;
    height: auto !important;
}

#map .leaflet-marker-icon .count {
    position: absolute;
    top: 0;
    left: 15px;
    width: 15px;
    height: 15px;
    text-align: center;
    font-size: 10px;
    border-radius: 20px;
    border: 1px solid #000;
    background: #00887a
        /*6B9362*/
    ;
    color: #fff;
}

#map .leaflet-marker-icon .multi-icon img {
    width: 32px;
    height: 32px;
}

#map .leaflet-marker-icon .normal-icon img {
    width: 20px;
    height: 32px;
}

#map .leaflet-popup-content {
    font-family: 'Nunito', sans-serif;
}

.details {
    flex-grow: 2;
    padding: 40px 0;
}

.details .content {
    padding: 0 20px;
    width: 80%;
    margin: 0 auto;
}

.details .loading,
.details .error {
    font-weight: 700;
    font-family: 'Amatic SC';
    font-size: 40px;
}

.details h1 {
    font-size: 40px;
    display: inline-block;
}

.details .latlng {
    display: inline-block;
    margin-left: 10px;
    font-style: italic;
    margin-bottom: 20px;
    font-size: 12px;
}

.details .back .button {
    margin-bottom: 20px;
    padding: 10px 20px
}

.details .poi {
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.details h2 {
    font-size: 40px;
    margin-bottom: 5px;
}

.details h3 {
    font-size: 25px;
}

.details .location {
    font-size: 14px;
    margin-top: 10px;
}

.details .location_desc {
    margin-top: 10px;
}

.details .date {
    font-style: italic;
    font-size: 12px;
    margin-top: 10px;
}

.details .city {
    font-size: 14px;
    margin-top: 10px;
}

.details .summary {
    margin-top: 10px;
}

.details .bf-stats {
    margin-top: 10px;
}

.details .caption {
    font-weight: bold;
}

.details #map {
    height: 300px;
    margin-bottom: 20px;
}

.states-page {
    flex-grow: 2;
}

.states-page .states {
    margin: 40px auto;
    width: 80%;
}

.states-page .states h1 {
    margin-bottom: 40px;
    margin-left: 10px;
}

.states-page .states h2 {
    margin-bottom: 10px;
    text-align: center;
}

.states-page .states .description {
    margin-left: 10px;
}

.states-page .states ul {
    display: flex;
    flex-wrap: wrap;
}

.states-page .states ul li {
    margin: 20px 10px;
    border-radius: 4px;
    padding: 10px 20px;
    width: 100%;
}

.states-page .states ul li .counts {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;
}

.states-page .states ul li .poi {
    width: 50%;
    display: flex;
}

.states-page .states ul li .poi img {
    width: 32px;
    height: 32px;
}

.states-page .states ul li .poi .count {
    line-height: 32px;
    padding-left: 10px;
}

.state-page {
    flex-grow: 2;
}

.state-page .error {
    font-family: 'Amatic SC';
    font-size: 40px;
}

.state-page .state {
    margin: 40px auto;
    width: 80%;
}

.state-page .state h1 {
    margin-bottom: 40px;
}

.state-page .state h2 {
    font-size: 30px;
}

.state-page .state .stat {
    margin-bottom: 80px;
}

.state-page .state .stat .no-data {
    margin-top: 20px;
}

.state-page .state .stat span {
    font-weight: bold;
}

.state-page .state .stat .graph {
    height: 400px;
}

.state-page .state .stat ul {
    margin-left: 20px;
    margin-top: 40px;
}

.state-page .state .stat ul>li {
    list-style: disc;
    margin-bottom: 10px;
}

.state-page .state .cities ul {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
}

.state-page .state .cities ul>li {
    width: 50%;
}

@media (min-width: 880px) {
    .states-page .states ul li {
        width: 41%;
    }

    .state-page .state .cities ul>li {
        width: 25%;
    }
}

@media (min-width: 1200px) {
    .states-page .states ul li {
        width: 27%;
    }
}

@media (min-width: 640px) {
    .filter-button {
        display: none;
    }
}

.credits {
    flex: 2 2;
    text-align: center;
    margin: 40px 0;
}

.credits h1 {
    margin-bottom: 20px;
}

.credits .data {
    margin-bottom: 10px;
}

.faq {
    width: 90%;
    margin: 40px auto;
}

.faq h1 {
    margin-bottom: 40px;
}

.faq p {
    margin-bottom: 40px;
}